import { ImageTheme } from '@/components/Image/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';
import React from 'react';
import { BaseSlot } from './slots/base';
import { GroupSlot } from './slots/group';

export const ImageContext = React.createContext({});

const $ = GenericSlotFactory({ theme: ImageTheme, context: ImageContext });

const Base = $({ as: BaseSlot });
const Byline = $({ as: 'span', slot: 'byline' });
const Caption = $({ as: 'span', slot: 'caption' });
const CaptionGroup = $({ as: 'figcaption', slot: 'captionGroup' });
const Group = $({ as: GroupSlot, slot: 'group' });

export const Image = Object.assign(Base, {
  Byline,
  Caption,
  CaptionGroup,
  Group,
});
