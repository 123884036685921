import { Link, LinkProps } from '@/components/Link';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { Image } from './Image';

export interface StandaloneImageProps extends StandaloneComponentProps<typeof Image> {
  alt?: React.ComponentProps<typeof Image>['alt'];
  byline?: React.ReactNode;
  caption?: React.ReactNode;
  link?: LinkProps;
  src?: React.ComponentProps<typeof Image>['src'];
  isBodytextChild?: boolean;
}

export const StandaloneImage: StandaloneComponent<StandaloneImageProps> = ({
  alt,
  byline,
  caption,
  link,
  src,
  ...props
}) => {
  return (
    <Image.Group {...props}>
      {link?.href && <Link {...mergeProps(link, { options: { cover: true } })} />}
      <Image {...{ src: src!, alt: alt! }} {...props} />
      {(caption || byline) && (
        <Image.CaptionGroup>
          {caption && <Image.Caption>{caption}</Image.Caption>} {byline && <Image.Byline>{byline}</Image.Byline>}
        </Image.CaptionGroup>
      )}
    </Image.Group>
  );
};
