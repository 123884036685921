import { ComponentProps } from '@/types/component';
import { omit } from 'lodash-es';
import { forwardRef } from 'react';

const imageAttributes = [
  'src',
  'alt',
  'srcset',
  'sizes',
  'crossorigin',
  'loading',
  'decoding',
  'width',
  'height',
  'referrerpolicy',
  // NextImage specific
  'priority',
  'quality',
  'placeholder',
  'blurDataURL',
  // Custom
  'maxWidth',
] as const;

interface GroupProps extends ComponentProps<'figure'> {}

// eslint-disable-next-line react/display-name
export const GroupSlot = forwardRef<HTMLVideoElement, GroupProps>(({ children, ...props }, ref) => {
  return (
    <figure ref={ref} {...omit(props, ...imageAttributes)}>
      {children}
    </figure>
  );
});
